<template>
  <footer :class="{ 'brdr-top-1 brdr-cl-secondary': isCheckoutPage }">
    <!-- <newsletter class=" flex brdr-bottom-1 brdr-cl-secondary" v-if="!isCheckoutPage" /> -->
    <div
      class="footer-links flex pt30 pb60 px40 bg-cl-secondary"
      v-if="!isCheckoutPage"
    >
      <div class="container">
        <div class="row m0 center-xs between-md">
          <div
            class="row m0 col-xs-12 start-md between-md between-xs "
            v-if="footerLinksColumns.length"
          >
            <div
              class="col-xs-12 col-md footer-responsivity"
              v-for="linksColumn in footerLinksColumns"
            >
              <h3 class="cl-accent weight-400 pb30 brdr-bottom-1 brdr-cl-secondary footer">
                <span>{{ linksColumn.title }}</span>
              </h3>

              <div :class="getColumnLinkContainerClass(link)" v-for="link in linksColumn.links" :key="link.id">

                <!-- Static page link -->
                <router-link
                  v-if="isStaticPageLink(link)"
                  class="cl-secondary footer-link"
                  :to="localizedRoute(link.link)"
                  exact
                >
                  {{ link.title }}
                </router-link>

                <div
                  v-if="isStaticPageLink(link) && hasLinkSocialLinks(link)"
                  class="social-icon-container"
                >
                  <a
                    v-for="socialLink in link.footerContent[0].SocialLink"
                    :href="socialLink.Link"
                    :aria-label="$t(socialLink.Label)"
                  >
                    <img
                      :src="'/assets/' + socialLink.IconName + '.svg'"
                      :alt="$t(socialLink.Label)">
                  </a>
                </div>

                <!-- Text (url) link -->
                <div
                  class="cl-secondary color-gray footer-link"
                  v-if="isTextLink(link)"
                >
                  <a
                    :href="link.url"
                    :aria-label="link.label"
                  >
                    {{ link.label }}
                  </a>
                </div>

                <!-- Empty row -->
                <div
                  class="cl-secondary"
                  v-if="isEmptyLink(link)"
                >
                  <br/>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container" v-if="isAnyrent()">
      <div class="row mt15 mb15">
        <div class="col-xs-12 info">
          <p>
            Máte dotazy ohledně dostupnosti, velikosti vybavení nebo dopravy? <br>
            Napište nám na <a href="mailto:info@anyrent.cz">info@anyrent.cz</a> nebo volejte Anyrent infolinku,
            dostupnou v pracovní dny mezi 9. a 17. hodinou na telefonu +420 737 520 829.
          </p>

          <span>Anyrent je členem rodiny Airwaynet a.s.</span>
          <ul class="airwaynet-links">
            <li>
              <a href="https://www.airwaynet.cz" target="_blank" class="footer-link">www.airwaynet.cz</a>
            </li>
            <li>
              <a href="https://anywherecampers.com" target="_blank" class="footer-link">www.anywherecampers.com</a>
            </li>
            <li>
              <a href="https://karavanycesko.cz" target="_blank" class="footer-link">www.karavanycesko.cz</a>
            </li>
            <li>
              <a href="https://www.praguecamp.cz" target="_blank" class="footer-link">www.praguecamp.cz</a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="container" v-if="isSbcr()">
      <div class="row mt15 mb15">
        <div class="col-xs-12 info">
          <p>
            Rádi Vám pomůžeme a poradíme.<br/>
            Volejte PO-PÁ: 10:30-19:30, SO-NE 10:00 - 19:00 na 222 367 700<br/>
            nebo pište kdykoli na <a href="mailto:dotazy@skiservis.cz" class="footer-link">dotazy@skiservis.cz</a>
          </p>
          <span><a href="https://www.kola-radotin.cz" target="_blank" class="footer-link">www.kola-radotin.cz</a></span>
          <br/>
          <span><a href="tel:222 367 700" class="footer-link">tel: 222 367 700</a></span>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row middle-xs px15 bottom-links">
        <!--
        <div class="col-xs-5 col-sm-3 cl-tertiary">
          <language-switcher v-if="multistoreEnabled" />
        </div>
        -->
        <div class="col-xs">
          <ul class="pl0 links" data-testid="bottomLinks">
            <li>
              <span class="version-info">{{ getVersionInfo }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <back-to-top bottom="20px" right="20px" visibleoffset="200">
      <button type="button" class="btn-top button no-outline brdr-none cl-white bg-cl-mine-shaft :bg-cl-th-secondary py10 px10">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
          <path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z" fill="white" />
        </svg>
      </button>
    </back-to-top>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'
import CurrentPage from 'theme/mixins/currentPage'
import BackToTop from 'theme/components/core/BackToTop'
import { getPathForStaticPage } from 'theme/helpers'
import config from 'config'
import { Logger } from '@vue-storefront/core/lib/logger'
import FOOTER_LINKS_QUERY from '../../../../graphql/strapi/queries/footerLinks.gql'
import anyrentConfig from '@vue-storefront/core/partner-config/config.anyrent';
import sbcrConfig from '@vue-storefront/core/partner-config/config.sbcr';

const footerLinkType = {
   STATIC_PAGE_LINK: 'ComponentFooterLinksStaticPageLink',
   TEXT_LINK: 'ComponentFooterLinksTextLink',
   EMPTY: "ComponentFooterLinksEmptyRow"
};

const transformFooterLinksQueryResponse = (footerLinksQueryResponse) => {
  const transformLinks = (footerColumn) => {
    return footerColumn.map(({
      __typename: componentType,
      ...data
    },
      index
    ) => {
        if (componentType === footerLinkType.STATIC_PAGE_LINK) {
          // drop link if it is empty
          if (!data.StaticPage.data) {
            return null;
          }

          const {
            Title,
            Slug,
            FooterContent
          } = data.StaticPage.data.attributes;

          return {
            id: index,
            type: componentType,
            link: '/static/' + Slug,
            title: Title,
            footerContent: FooterContent
          }
        }

        if (componentType === footerLinkType.TEXT_LINK) {
          return {
            id: index,
            type: componentType,
            url: data.Url,
            label: data.Label,
          }
        }

        if (componentType === footerLinkType.EMPTY) {
          return {
            id: index,
            type: componentType,
          };
        }

        return null;
      }).filter((link) => !!link);
  }

  const {
    LeftColumn,
    CenterColumn,
    RightColumn,
    LeftColumnTitle,
    CenterColumnTitle,
    RightColumnTitle
  } = footerLinksQueryResponse.data.footerLinks.data[0].attributes;

  const footerLinksColumns = [
    { title: LeftColumnTitle, links: transformLinks(LeftColumn) },
    { title: CenterColumnTitle, links: transformLinks(CenterColumn) },
    { title: RightColumnTitle, links: transformLinks(RightColumn) },
  ];

  return footerLinksColumns;
}

export default {
  mixins: [CurrentPage],
  name: 'MainFooter',
  methods: {
    goToAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup')
    },
    getLinkFor (path) {
      return localizedRoute(getPathForStaticPage(path))
    },
    getColumnLinkContainerClass (link) {
      if (this.hasLinkSocialLinks(link)) {
        return 'mt10 link-with-social-links';
      }
      return 'mt10';
    },
    hasLinkSocialLinks (link) {
      return !!link.footerContent?.length;
    },
    isStaticPageLink (link) {
      return link.type === footerLinkType.STATIC_PAGE_LINK;
    },
    isTextLink (link) {
      return link.type === footerLinkType.TEXT_LINK;
    },
    isEmptyLink (link) {
      return link.type === footerLinkType.EMPTY;
    },
    getPartnerId () {
      return this.$ssrContext?.partnerData.id || config.partnerData.id;
    },
    isAnyrent () {
      return this.getPartnerId() === anyrentConfig.id;
    },
    isSbcr () {
      return this.getPartnerId() === sbcrConfig.id;
    },
    fetchFooterLinks (partnerId) {
      return new Promise(async(resolve, reject) => {
        try {
          const footerLinksQueryResponse = await this.$apollo.query({
            query: FOOTER_LINKS_QUERY,
            variables: {
              "filters": {
                "Partner": {
                  "Name": {
                    "eq": partnerId
                  }
                }
              }
            }
          });

          this.footerLinksColumns = transformFooterLinksQueryResponse(footerLinksQueryResponse);
          resolve();
        } catch (error) {
          Logger.error('Cannot load menu items on static page: ' + error);
          reject();
        }
      });
    }
  },
  data () {
    return {
      footerLinksColumns: [],
    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'user/isLoggedIn',
    }),
    multistoreEnabled () {
      return config.storeViews.multistore
    },
    getVersionInfo () {
      return `v${(this.$ssrContext?.vs.config || config).version} ${process.env.__BUILDTIME__}`
    }
  },
  serverPrefetch () {
    return this.fetchFooterLinks(this.$ssrContext.partnerData.id);
  },
  async mounted () {
    this.fetchFooterLinks(config.partnerData.id);
  },
  components: {
    BackToTop
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-secondary: color(secondary);
$color-silver: color(silver);

h3{
  font-size:21px;
}
.mt10 {
  margin-top: 10px;
}

.flex-md{
  display: flex;
}
.ml5 {
  margin-left: 5px;
}
h3.footer{
  font-family: 'TTCommons-Medium';
}
.icon {
  transition: 0.3s all;
}

.footer-link:hover {
    text-decoration: underline;
    text-decoration-color: $color-silver;
}

.social-icon {
  width: 40px;
  height: 40px;
  &:hover,
  &:focus,
  &:active {
    .icon {
      fill: $color-secondary;
    }
  }
}

.color-gray {
  color: color(gray);

  & a {
    color: color(gray);
  }
}

.links {
  list-style-type: none;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.bottom-links {
  @media (max-width: 767px) {
    padding: 0;
  }
}

.version-info {
  display: inline-block;
  color: $color-secondary;
  font-size: 0.7rem;
  @media (min-width: 768px) {
    margin-right: 1rem;
    font-size: 0.8rem;
  }
}

.underline {
  &:hover {
    color: $color-secondary;
    &:after {
      background-color: $color-secondary;
    }
  }
}

.justify-left {
  justify-self: left;
}

.justify-right {
  justify-self: right;
}
.info {
  color: #828282;
  font-size: 14px;
  line-height: 1.425;

  text-align: center;

  a {
    color: inherit;
    // text-decoration: underline;
  }
}

.display-none--after:after {
  display: none;
}

.mt30 {
  margin-top: 30px;
}
.mt83 {
  margin-top: 83px;
}

.airwaynet-links {
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  list-style: none;

  > li {

    &:not(:last-child):after {
      content: '|';
      display: inline-block;
      vertical-align: middle;
      margin: 0 12px;

      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
}
.flex-md {
  @media (max-width: 767px) {
   display: block;
  }
}
.footer-responsivity {
  @media (max-width: 767px) {
        padding-top: 35px;
      }
}

.link-with-social-links {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.social-icon-container {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  height: 0px;
  width: 100%;

  & a {
    display: flex;
    flex-direction: row;
    align-items: center;

    &::after {
      display: none;
    }
  }

  & img {
    width: 19px;
    height: 19px;
  }
}
@media (max-width: 767px) {
  .social-icon-container {
    height: 100%;
    margin-top: 10px;
    margin-left: auto;
    justify-content: center;
  }

  .link-with-social-links {
    margin-left: auto;
    margin-right: auto;
    width: min-content;
    flex-direction: column;
  }
}

.footer-responsivity h3 {
  @media (max-width: 767px) {
        border: none;
        padding-bottom: 5px;
        font-size: 1.4em;
      }
}
.footer-responsivity a {
  @media (max-width: 767px) {
        font-size: 1.2em;
      }
}
@media (max-width: 767px) {
  .mt15-mobile {
    margin-top: 15px;
  }
}
@media (max-width: 595px) {
  .no-mobile {
    display: none;
  }
  .float-left{
    float: left;
  }
  .social {
    margin-top: 0;
  }

  .footer-links {
    padding-bottom: 30px;
  }
}
</style>
